

.CvContainer {
    margin: 5px 10px;
    
    width: 100vw;
    /* height: 99vh; */

    /* border: solid 3px #3df9ff;
    border-radius: 15px 5px 15px 15px;
    box-shadow: 0px 0px 25px #21deff, 0 0 35px #049aa5 inset; */

    color:#effeff;
    text-shadow: 5px 0px 30px #3df9ff, 0px 0px 50px #3df9ff;

    /*background: linear-gradient(#000000, #4e0f0f);*/
    
    display: flex;
    
    flex-direction: row;
    align-items: center;
}

.CvDownload {
    padding-top: 20px;
    padding-bottom: 20px;
}

.CvHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 auto;
    width: 45%;
    
}

.CvHeader a{
    color:#3df9ff;
    text-decoration: none;
}
.CvHeader a:hover{
    color:white;
} 

.CvHeader p {
    margin-top: 5px;
    width: 75%;
    font-size: 1.1vw;
}

.CvInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    width: 45%;
    
}


.CvInfo h1:hover {
    color: white;
    
}

.CvInfoHeader {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;

    color: white;
    
    margin-top: 20%;
    width: 98%;

    font-size: 2vw;
    
}

.CvInfoHeader :hover {
    
    text-shadow: 5px 0px 30px white, 0px 0px 30px white;
    cursor: pointer;
}

.CvPartContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    width: 80%;
    
    margin-top: 10%;
    color: rgb(255, 255, 255);
    
}

.CvPartContainer h1 {
    font-size: 2vw;
}

.CvPartContainelement {
    font-size: 1vw;
    margin-top: 8px;
    color: rgb(227, 253, 255);

    width: 90%;
    height: 40px;
    will-change: transform, opacity;

}
/* 
------------------------------- */


.CvInfoText {
    margin: 5px;
    
    display: flex;
    align-items: center;
    flex-direction: column;
    
}

.CvProgressContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.CvProgressContainerElement {
    margin: 15px;
    opacity: 0.65;
}

.Progress {
    
    width: 200px;
    height: 20px;
    opacity: 1;
    border-radius: 25px;
    background-color: grey;
    margin: 5px;
    font-size: 1rem;
    text-align: center;

    display: flex;
    align-items: center;
    flex-direction: row;
    
    text-shadow: 5px 0px 30px #3df9ff, 0px 0px 50px #3df9ff;

}

.ProgressDone {
    width: 0;
    height: 20px;
    border-radius: 25px;
    background: linear-gradient(to left,#e66465, #9198e5);
    opacity: 0;
    
    box-shadow: 0 0 10px rgb(255, 144, 144);
    
    transition: 2s ease;

}
@media only screen and (max-width: 1024px) {
    .CvContainer {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;


    }

    .CvHeader {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        

        min-width: 98%;
        
    }

    .CvHeaderP {
        
        max-width: 94%;
        font-size: 2vw;

    }

    .CvInfo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        min-width: 98%;

    }
    .CvInfoHeader{
        margin-top: 10px;
        font-size: 3vw;
    
    }

    .CvPartContainer {

        display: flex;
    
        min-width: 98%;
        height: 80%;
        margin: 0 auto;
    }

    .CvPartContainelement {
        margin: 0 auto;
        font-size: 2vw;
    }
}

  @media only screen and (max-width: 600px) {

    .CvContainer{
        padding-top: 40px;
        padding-bottom: 30px;
        
        
    }

    .CvInfo {
        
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        
    }
    
    .CvDownload {
        padding-top: 20px;
        cursor: pointer;
        padding-bottom: 20px;
    }

    .CvHeader {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        

        min-width: 98%;
        
        
    }

    .CvHeader h1 {
        font-size: 50px;
    }

    .CvHeaderP {
        
        max-width: 94%;
        font-size: 3vw;
        
    }

    .CvInfo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        min-width: 98%;
        
        padding-top: 20px;

    }
    .CvInfoHeader{
        margin-top: 10px;
        font-size: 5vw;
        padding-top: 20px;
    }

    .CvPartContainer {

        display: flex;
    
        min-width: 98%;
        
        margin: 0 auto;

        padding-top: 30px;
        padding-bottom: 25px;
    }

    .CvPartContainelement {
        margin: 0 auto;
        font-size: 3vw;
        padding-top: 7px;
    }
   
    .Progress {
        
        width: 33vw;
        height: 3vw;
        opacity: 1;
        border-radius: 25px;
        background-color: grey;
        margin: 2px;
        font-size: 1rem;
        text-align: center;
        will-change: opacity, height, width;
        
    }
    
    .ProgressDone {
        width: 0;
        height: 20px;
        border-radius: 25px;
        background: linear-gradient(to left,#e66465, #9198e5);
        opacity: 0;
        
        box-shadow: 0 0 10px rgb(255, 144, 144);
        
        transition: 2s ease;
        will-change: opacity, height, width;
    }
  }
  
  