.Nord-Hist-Privacy-Main {
    width: 100%;
    height: 100%;

    background-color: rgb(71, 97, 42);

    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.Nord-Hist-Privacy-Header {
    color: white;
    font-size: 4em;

}

.Nord-Hist-Privacy-Header p {
    font-size: 0.5em;
}


.Nord-Hist-Privacy-Body {
    color: white;
    width: 80%;
    word-break: break-word;
}

