.Header {
    /*vh eller % */
    height: 98vh;
    width: 100vw;
    margin: 5px 10px 5px 10px;
    
    /* border: solid 3px #7a04eb;
    border-radius: 5px 5px 30px 30px;
    box-shadow: 0px 0px 25px #7a04eb, 0 0 25px #8b2ae6 inset; */

    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    /* background-image: url(./kebne.jpg); */
}

.HeaderText {
    color: white;
    /*font-family: 'Sacramento', cursive; */
    text-shadow: 2px 2px 15px #7a04eb, 0 0 15px #8b2ae6;
}

.HeaderLinks {
    margin-top: 20%;
    
    color: #7a04eb;
    
    text-align: center;
    align-items: center;
    display: flex; 
    flex-direction: row;
    justify-content: space-around;

    font-size: 30px;
}
/* adds effects to svg.icon */
.HeaderLinks{filter:drop-shadow(2px 2px 5px rgb(94, 16, 219))}

/*name all buttons or have the same effect on all ?*/
.Header-Icon:hover {
    color: white;
}

a {
    /* sets color on a elements to normal and not a click link color */
    color: #c8a4f8;
}

h1 {
    font-size: 8vh;
}
h2 {
    font-size: 4vh;
}
h5 {
    font-size: 2vh;
}

@media only screen and (max-width: 600px){
    .HeaderLinks {
        margin: 0 auto;
        font-size: 4vh;
        color: white;
        width: 100%;
        text-align: center;
        align-items: center;
        display: flex; 
        flex-direction: row;
        justify-content: space-around;
    }
}

@media only screen and (max-height: 600px){
    .HeaderLinks {
        width: 100%;
        display: flex;
        align-items: center;
        
    }

    .Header-Icon {
        font-size: 30px;
    }
}