.Navbar {
    top: -1px;
    
    width: 100%;
    height: 5vh;
    margin: 5px 10px 5px 10px;

    /* border: solid 2px #ff00a0;
    border-radius: 20px 8px 20px 20px;
    box-shadow: 0px 0px 30px #ff00a0, 0 0 25px #ff00a0 inset; */
    
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    text-decoration: none;
    text-align: center;

    z-index: 10;

    transition: 
        width 0.2s ease-in-out, height 0s ease-in-out;
}

.NavbarText {
    width: 100%;
    font-size: 2vh;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    
    text-shadow: 2px 2px 15px #ff00a0, 0 0 15px #ff00a0;
    

    transition: width 0.2s ease-in-out;
}
.NavbarText a{
    text-decoration: none;
    width: 15vw;
    border: 1px solid #ff00a0;
    border-radius: 25px;
    color: white;
    transition: 
    width 1s, height 1s;

    transform-origin: 4px 0px;
  
    box-shadow: 2px 2px 15px #ff00a0, 0 0 15px #ff00a0 inset;
}

.NavbarTextAnimated {
    
    width: 100%;
    font-size: 2vh;
    color: #ff00a0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    
    text-shadow: 2px 2px 15px #ff00a0, 0 0 15px #ff00a0;
    
    transition: width 0.2s ease-in-out;
}

.NavbarTextAnimated a{
    text-decoration: none;
    width: 15vw;
    border: 1px solid #ff00a0;
    border-radius: 25px;
    color: rgb(253, 224, 253);
    transition: 
    width 1s, height 1s;

    transform-origin: 4px 0px;

    box-shadow: 2px 2px 15px #ff00a0, 0 0 15px #ff00a0 inset;
  
}

.Navbar a:hover{
    background-color: #ff46bb;
    transition: 0.5s ease-in-out;
}


/*Transition down */

.NavbarEffect {
    top:-1px;
    right: -1px;
    position: sticky;
    width: 40px;
    height: 40px;
    margin: 5px 10px 5px 10px;
    

    border: solid 2px rgb(0, 255, 179);
    border-radius: 20px 8px 20px 20px;
    box-shadow: 0px 0px 30px #32f7bc, 0 0 25px #05d194 inset;
    
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-decoration: none;
    text-align: center;
    text-shadow: 0px 0px 10px #32f7bc, 0 0 10px #05d194 inset;
    z-index: 10;

    transition: ease-in-out;
    
}

.NavbarEffect :hover {
   cursor: pointer;
}
.NavbarTextEffect {
    
    width: 10%;
    font-size: 2vh;
    color: rgb(0, 255, 157);
    display: flex;
    flex-direction: column;
    
    align-items: center;
    
    
    text-shadow: 2px 2px 15px rgb(0, 190, 158), 0 0 15px rgb(60, 255, 190);
}

.NavbarTextEffect :hover {
    color: blue;
}


.NavbarTextEffect a{
    display: none;
    
}

/* use transition here */
.NavbarTextEffectAnimated {
    margin-top: 200px;
    margin-right: 80px;

    transition: margin-top 0.2s ease-in, margin-right 0.2s ease-in;
    text-shadow: 2px 2px 15px rgb(0, 190, 158), 0 0 15px rgb(60, 255, 190);
}

.NavbarTextEffectAnimated a{

    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 80px;
    height: 30px;
    margin: 5px;
    margin-left: 20px;
    
    text-decoration: none;
    text-shadow: 2px 2px 15px rgb(0, 190, 158), 0 0 15px rgb(60, 255, 190);
    
    border: 1px solid rgb(97, 255, 221);
    box-shadow: 0px 0px 15px #32f7bc, 0 0 15px #05d194 inset;
    border-radius: 25px;
    color: rgb(255, 255, 255);
    font-size: 80%;

    font-stretch: condensed;
    transition: margin-top 0.2s ease-in, margin-left 0.2s ease-in;

    
}

.NavbarTextEffectAnimated :hover {
    background-color: #32f7bc;
}

.NavbarButton{
    width: 100%;
    height: 100%;

    position: absolute;

    padding-top: 1rem;

    margin: auto;
    color: white;

    display: flex;
    justify-content:  center;
    text-align: center;
    

    transition: display 0.2s ease-in-out;
}
.NavbarButton-enter {
    opacity: 0.01;
    transform: scale(1.1);
  }
  
.NavbarButton-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: all 300ms;
}

.moreButton {
    width: 100%;
    height: 100%;
    
}

.closeButton {
    width: 100%;
    height: 100%;
}


@media only screen and (max-width: 680px){
  
    .NavbarText a{
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        font-size: 3vw;
        width: 20%;
        height: 30px;
        
    }

    .NavbarTextAnimated a{
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        width: 20%;
        height: 30px;
        font-size: 3vw;

    }
    .NavbarTextEffectAnimated {
        margin-right: 70px;
        
    }

    
}

@media only screen and (max-height: 480px) {
    .NavbarTextEffectAnimated {
        padding-top: 120px;
        margin-right: 70px;

    }

    .Navbar {
        padding-top: 8px;
        font-size: 20px;
    }

    .NavbarText {
        padding-top: 5px;
    }

    .NavbarText a{
        
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        height: 20px;
        font-size: 4vh;
    }

    .NavbarTextAnimated a {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        height: 20px;
        font-size: 4vh;
    }
}